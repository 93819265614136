.default-template {
  > .menu {
    background-color: var(--bg-dark);
    display: block;
    transition: flex-basis 300ms ease-in-out;
    flex-basis: 0;
    overflow: hidden;

    h1 {
      height: 65px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      color: #fff;
      font-family: "DM Sans Bold";
      font-weight: 700;
      font-size: 17px;
      align-items: center;
      justify-content: center;
      img {
        margin: 0 15px;
        height: 40px;
      }
    }
    .space {
      height: 80px;
    }
    ul {
      list-style: none;
      padding: 0;
      li.item {
        align-items: center;
        color: #fff;
        line-height: 44px;
        box-sizing: border-box;
        cursor: pointer;
        transition: background-color 300ms ease-in-out 1ms;
        padding: 6px;
        padding-left: 40px;
        font-family: "DM Sans Bold";
        font-weight: 700;
        opacity: 0.6;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          b {
            flex-basis: 130px;
          }
        }
        &.active {
          background: linear-gradient(to right, var(--bg-dark), var(--primary-dark));
          opacity: 1;
        }
        img:first-child {
          height: 24px;
          width: 24px;
          margin-right: 15px;
        }
        ul {
          height: auto;
          max-height: 0;
          transition: max-height 300ms ease-in-out 1ms;
          overflow: hidden;
          padding-left: 40px;
          li {
            a {
              display: block;
              line-height: 20px;
              font-size: 14px;
              padding: 10px;
              color: #fff;
              text-decoration: none;
            }
            opacity: 0.5;
            &.active {
              opacity: 1;
            }
          }
        }
        &.active ul {
          max-height: 600px;
        }
      }
    }
    &.active {
      flex-basis: 250px;
      ul {
        li.item {
          padding-left: 20px;
        }
      }
    }
  }
  @media (min-width: 700px) {
    .menu {
      flex-basis: 250px;
      &.active {
        ul {
          li.item {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
