* {
  margin: 0;
}
:root {
  --primary-dark: #18a0fb;
  --secondary-dark: #4472bb;
  --bg-dark: #2f4f83;
  --text-light: #fff;
  --bg-main: #ededed;
}
@font-face {
  font-family: 'DM Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Regular'), url('./assets/fonts/DMSans-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Italic'), url('./assets/fonts/DMSans-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium'), url('./assets/fonts/DMSans-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium Italic'), url('./assets/fonts/DMSans-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold'), url('./assets/fonts/DMSans-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold Italic'), url('./assets/fonts/DMSans-BoldItalic.woff') format('woff');
  }

  body {
    font-size: 16px;
    font-family: 'DM Sans Regular';
    color: #4c4c4c;
  }