.settings {
    .profile {
        form {
            max-width: 900px;
            textarea {
                min-height: 70px;
            }
            .sub-header {
                border-bottom: 1px solid #c5c5c5;
                margin-bottom: 20px;
                h4 {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #0b2a5c;
                    display: inline-block;
                    margin: 10px 0 -1px 0;
                    font-family: "DM Sans Bold";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #020e23;
                }
            }
        }
    }
}