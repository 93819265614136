section.auth {
    background: #fff;

    > div .container {
        max-width: 400px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 15px;
    }

    header {
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 500;
            align-items: center;
            font-family: "DM Sans Bold";
            font-size: 17px;
            color: #020e23;
        }
        img {
            height: 48px;
            margin-right: 20px;
        }
    }
    .flag {
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 10px;
        &.error {
            color: #721c24;
            background-color: #f8d7da;
            border: 1px solid #f5c6cb;
        }
        &.success {
            color: #2d673a;
            background-color: #d5eddb;
            border: 1px solid #c4e6cc;
        }
    }
    background: #fff;
    h3 {
        font-size: 16px;
        font-weight: normal;
        font-family: "DM Sans Regular";
        font-size: 34px;
        margin: 20px 0;
        text-align: center;
    }
    .extras {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        a {
            color: #37ac83;
            text-decoration: none;
            font-family: "DM Sans Regular";
            font-size: 17px;
        }
    }
    div.actions {
        margin: 20px 0 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
            text-transform: capitalize;
            font-size: 15px;
            line-height: 35px;
            border: none;
            color: var(--text-light);
            font-weight: 400;
            cursor: pointer;
            width: 100%;
            max-width: 220px;
            border-radius: 5px;
            background-color: var(--secondary-dark);
            box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        }

        button:focus {
            outline: none;
        }
    }
    footer {
        padding: 20px 0;
        margin: 10px auto;
        ul {
            list-style-type: none;
            list-style: none;
            border-bottom: 1px solid #bdc4c9;
            padding: 10px;
            max-width: 500px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            li {
                margin: 0 auto;
                a {
                    color: var(--secondary-dark);
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }
        p {
            line-height: 40px;
            font-size: 13px;
            text-align: center;
            color: #868686;
        }
    }
}
@media (min-width: 700px) {
    section.auth {
        display: flex;
        flex-direction: row;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        > div {
            flex-basis: 50%;
            height: inherit;
        }

        > div:last-child {
            background: linear-gradient(120.07deg, #5d13b0 22.71%, #0a1679 85.57%);
            > div {
                background: url(../../assets/images/patterns.png);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                img {
                    height: 540px;
                }
                .timer {
                    display: flex;
                    flex-direction: column;
                    max-width: 310px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    color: #fff;
                }
                .timer h4 {
                    font-family: DM Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 22px;
                    line-height: 40px;
                    letter-spacing: -0.03em;
                    text-align: center;
                
                }
                .timer > div {
                    border: 1px solid #fff;
                    border-radius: 40px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                }
                .timer > div > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 70px;
                }
                .timer strong {
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 27px;
                }
                .timer span {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
    }
}
