.settings {
    .change-password {
        .header {
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            h4 {
                font-style: normal;
                font-weight: 500;
                color: #020e23;
            }
            .buttons {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                button,
                a {
                    font-family: "DM Sans Bold";
                    min-width: 124px;
                    margin: 0 5px;
                    line-height: 25px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    padding: 10px;
                    box-sizing: border-box;
                    img {
                        margin-right: 10px;
                    }
                    cursor: pointer;
                }
                button:focus,
                a:focus {
                    outline: none;
                }
                button {
                    border: 1px solid #020e23;
                    color: #020e23;
                }
                a {
                    background-color: var(--primary-dark);
                }
            }
        }
    }
}
